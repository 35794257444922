import { Injectable } from '@angular/core';
import { Country, DataList, FetchDataPayloadParamsPayload } from '@shared/models/data.model';
import { EMPTY, Observable, of } from 'rxjs';
import { catchError, concatMap, expand, map, shareReplay, tap, toArray } from 'rxjs/operators';
import { DataService } from './data.service';

@Injectable({
  providedIn: 'root',
})
export class CountriesService {
  public countries$: Observable<Country[] | null> = this.fetchCountries().pipe(
    expand(({ next }) => (next ? this.fetchCountries() : EMPTY)),
    concatMap((response) => response['hydra:member']),
    toArray(),
    catchError(() => of(null)),
    shareReplay(1)
  );

  private stateParams: Partial<FetchDataPayloadParamsPayload> = { page: '1', perPage: '30' };

  private fetchedItems: number = 0;

  constructor(private dataService: DataService<unknown>) {}

  private fetchCountries(): Observable<DataList<Country>> {
    return this.dataService
      .fetchDataList<Country>(
        {
          root: 'countries',
          category: 'countries',
        },
        this.stateParams
      )
      .pipe(
        tap((response) => {
          this.fetchedItems += response['hydra:member'].length;
          this.stateParams.page = (Number(this.stateParams.page) + 1).toString();
        }),
        map((response) => ({ ...response, next: response['hydra:totalItems'] > this.fetchedItems }))
      );
  }
}
